<template>
    <div id="global-controls">
        <BPM />
    </div>
</template>

<script>
import BPM from '@/components/synth/bpm'

export default {
    
    components: {
        BPM
    }
}
</script>